import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useEventList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refInactiveArticlesTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'title',
      label: 'Title',
      width: '7%',
      sortable: true,
    },
    {
      key: 'content',
      label: 'Content',
      sortable: true,
    },
    {
      key: 'category',
      label: 'Category',
      sortable: true,
    },
    {
      key: 'createdBy',
      label: 'Created by',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalArticles = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'name')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const articleCategoryFilter = ref([])
  if (Array.isArray(route.query.articleCategory)) {
    articleCategoryFilter.value = route.query.articleCategory
  } else if (route.query.articleCategory) {
    articleCategoryFilter.value = [route.query.articleCategory]
  }

  const toDeletedID = ref(null)
  const type = ref('inactive')
  const articleData = ref([])
  const startDateFilter = ref(route.query.startDate || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refInactiveArticlesTable.value ? refInactiveArticlesTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalArticles.value,
    }
  })

  const refetchData = () => {
    refInactiveArticlesTable.value.refresh()
  }

  watch([searchQuery, articleCategoryFilter, startDateFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchArticles = (ctx, callback) => {
    store
      .dispatch('agencies-inactive-articles/fetchArticles', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        category: articleCategoryFilter.value,
        startDate: startDateFilter.value,
        type: type.value,
      },
      { root: true })
      .then(response => {
        const { articles, total } = response.data
        articleData.value = articles
        callback(articles)
        totalArticles.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              category: articleCategoryFilter.value,
              startDate: startDateFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteArticle = () => {
    store
      .dispatch('agencies-inactive-articles/deleteArticle', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveArticleStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchArticles,
    tableColumns,
    perPage,
    currentPage,
    totalArticles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInactiveArticlesTable,

    resolveArticleStatusVariant,
    refetchData,

    toDeletedID,
    deleteArticle,
    articleData,

    // Extra Filters
    articleCategoryFilter,
    startDateFilter,
  }
}
